const localStorageKey = 'productsRecentlyViewed'

export default {
	name: 'productHistory',
	component() {
		return {
			productHistory: [] as any[],
			currency: '',

			async init() {
				const productHandles = this.getSavedHandles()

				if (productHandles.length === 0) {
					this.hide()
				} else {
					const fetchPromises = productHandles.map(productHandle =>
						// @ts-expect-error window.Shopify is always defined
						fetch(`${window.Shopify.routes.root}products/${productHandle}.js`)
							.then(response => response.json())
							.catch(error => console.error('[productHistory]:', error))
					)

					await Promise.allSettled(fetchPromises)
						.then(results => {
							const historyWithData = [] as any[]
							const updatedKeys = [] as string[]

							results.map(result => {
								if (result.status === 'fulfilled') {
									const product = result.value
									const data = this.getProductData(result.value)
									historyWithData.push(data)
									updatedKeys.push(product.handle)
								}
							})

							this.productHistory = historyWithData
							localStorage.setItem(localStorageKey, JSON.stringify(updatedKeys))
						})
						.catch(error => console.error('[productHistory]:', error))
				}

				this.updateHistory()
			},

			getProductData(product) {
				const price = (product.price / 100).toLocaleString('en-US', { style: 'currency', currency: 'EUR' })
				const formattedPrice = price.replace('$', '€')
				return {
					...product,
					...this.getSrc(product.featured_image),
					hover: {
						...this.getSrc(product.images[1] || product.images[0])
					},
					price: formattedPrice
				}
			},

			getSrc(url) {
				const mobileSizes = [48, 96, 160, 320, 640]
				const desktopSizes = [960, 1200]

				const desktopRatio = 435 / 323
				const mobileRatio = 260 / 194

				const mobileUrlSrc = mobileSizes.map(size => `${url}&crop=center&height=${Math.round(mobileRatio * size)}&width=${size} ${size}w`).join(',')
				const desktopUrlSrc = desktopSizes
					.map(size => `${url}&crop=center&height=${Math.round(desktopRatio * size)}&width=${size} ${size}w`)
					.join(',')
				const baseUrl = `${url}&crop=center&height=${435}&width=${323}`

				return { baseUrl, mobileUrlSrc, desktopUrlSrc }
			},

			hide() {
				// @ts-expect-error $el is always defined on Alpine comps
				;(this.$el as HTMLElement).style.display = 'none'
			},

			getSavedHandles() {
				let productHandles = []

				try {
					productHandles = JSON.parse(localStorage.getItem(localStorageKey) || '[]')
				} catch (error) {
					localStorage.setItem(localStorageKey, JSON.stringify([]))
					console.warn('[productHistory]: could not read history, resetting')
				}

				return productHandles
			},

			updateHistory() {
				const productHandles = this.getSavedHandles()
				const root = document.querySelector(`[data-taxi-view='product']`) as HTMLElement
				const productHandle = root?.dataset?.productHandle
				// only exists on PDP pages
				if (productHandle) {
					const recentlyViewed = [productHandle, ...productHandles].filter((entry, idx, arr) => arr.indexOf(entry) === idx).slice(-4)
					localStorage.setItem(localStorageKey, JSON.stringify(recentlyViewed))
				}
			}
		}
	}
}
