export default {
	name: 'freeProducts',
	component(props) {
		const { productIds } = props

		return {
			selectFreeProduct(variantId) {
				const items = window.liquidAjaxCart.cart.items
				const discountedCartItem = items.find(item => {
					return item.final_price === 0 && productIds.includes(item.product_id)
				})

				const addFreeProduct = () => {
					window.liquidAjaxCart.add({
						items: [
							{
								id: variantId,
								quantity: 1,
								properties: {
									// eslint-disable-next-line @typescript-eslint/naming-convention
									_discount_kit: 'free'
								}
							}
						]
					})
				}

				if (discountedCartItem && discountedCartItem.variant_id !== variantId) {
					window.liquidAjaxCart.change({ id: discountedCartItem.key, quantity: 0 })
					addFreeProduct()
				} else if (!discountedCartItem) {
					addFreeProduct()
				}
			}
		}
	}
}
