export default {
	name: 'floatingButton',
	component() {
		return {
			visible: false,

			init() {
				window._lenis.on('scroll', this.handleScroll.bind(this))
				// @ts-expect-error Alpine is not typed
				this.$el.classList.remove('translate-y-full')
			},

			handleScroll() {
				const scrollPosition = window._lenis?.actualScroll || 0
				const shouldBeHidden = Boolean(scrollPosition > 700)
				if (shouldBeHidden !== this.visible) {
					this.visible = shouldBeHidden
					document.documentElement.classList.toggle('global-banner-hidden')
				}
			}
		}
	}
}
